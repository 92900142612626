import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subject, Subscription, concatMap, filter, from, fromEvent, of, takeUntil } from 'rxjs';
import { LanguageType, UserModel } from '../../shared/interfaces';
import { BroadcastService, HandleTranslateService, IdleService } from '../../shared/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public user: UserModel | undefined;

  public language: LanguageType;
  
  public username: string | null  = '';
  public toggleNav: boolean = true;

  constructor(
    private authenticationService: AuthenticationService,
    private handleTranslateService: HandleTranslateService,
    private broadcastService: BroadcastService,
    private idleService: IdleService
  ) {
    this.subscribeToServices();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
    this.authenticationService.user?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.user = x);
  }

  public onChangeLanguage(language: LanguageType): void {
    this.broadcastService.sendMessage({ type: 'language-change', value: language });
    this.handleTranslateService.setLanguage(language); 
  }

  public isKeyinPermission(): boolean {
    return this.authenticationService.isKeyinPermission();
  }

  public isSettingPermission(): boolean {
    return this.authenticationService.isSettingPermission();
  }

  public isSubmitToRdPermission(): boolean {
    return this.authenticationService.isSubmitToRdPermission();
  }

  public isRevenueDocumentPermission(): boolean {
    return this.authenticationService.isRevenueDocumentPermission();
  }

  public isSalesTaxReportPermission(): boolean {
    return this.authenticationService.isSalesTaxReportPermission();
  }

  public isDocumentSearchPermission(): boolean {
    return this.authenticationService.isDocumentSearchPermission();
  }

  public isRdSummaryPermission(): boolean {
    return this.authenticationService.isRdSummaryPermission();
  }

  public isFeeEnginePermission(): boolean {
    return this.authenticationService.isFeeEnginePermission();
  }

  public isDocumentUploadPermission(): boolean {
    return this.authenticationService.isDocumentUploadPermission();
  }

  public isBulkTaxReportPermission(): boolean {
    return this.authenticationService.isBulkTaxReportPermission();
  }

  public isAnnouncementPermission(): boolean {
    return this.authenticationService.isAnnouncementPermission();
  }

  public isDashboardPermission(): boolean {
    return this.authenticationService.isDashboardPermission();
  }

  public isMonitoringPermission(): boolean {
    return this.authenticationService.isMonitoringPermission();
  }

  public isEmailDashboardPermission(): boolean {
    return this.authenticationService.isEmailDashboardPermission();
  }

  public isDataPurgePermission(): boolean {
    return this.authenticationService.isDataPurgePermission();
  }
  
  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  isMaker(){
    return this.authenticationService.isMaker();
  }

  isChecker(){
    return this.authenticationService.isChecker();
  }

  isSuperChecker() {
    return this.authenticationService.isSuperChecker();
  }

  isViewerOperation() {
    return this.authenticationService.isViewerOperation();
  }

  isViewerImpAndHelp() {
    return this.authenticationService.isViewerImpAndHelp();
  }

  isSCBBCMManageUser() {
    return this.authenticationService.isSuperMaker() || this.authenticationService.isMaker() || this.authenticationService.isSuperChecker()
      || this.authenticationService.isChecker();
  }

  isSCBBCMViewer() {
    return this.isViewerImpAndHelp() || this.isViewerOperation();
  }

  public onLogout(): void {
    this.idleService.clearIdle();
    this.authenticationService.sendLogoutRequest();
    this.authenticationService.onLogout();
    this.broadcastService.sendMessage({ type: 'logout' });
  }

}
