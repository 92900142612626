import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { API_CONSTANT } from './api.constant';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { Router } from '@angular/router';
import { ResponseMessageLoginModel, ResponseMessageProfileModel, UserModel } from '../shared/interfaces';
import { CookieService } from '../shared/services';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  private userSubject: BehaviorSubject<UserModel | undefined> | undefined = new BehaviorSubject<UserModel | undefined>(undefined);
  public user: Observable<UserModel | undefined> | undefined = undefined;

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.user = this.userSubject?.asObservable();
  }

  public setCurrentUser(user: UserModel): void {
    this.setUserValue(user);
    this.setTokenValue(user.token);
  }

  public setUserValue(user: UserModel): void {
    this.userSubject?.next(user);
  }

  public setTokenValue(token: string) {
    this.cookieService.setCookie('currentUser', token);
  }

  public getCurrentUserValue(): UserModel | undefined {
    return this.userSubject?.value;
  }

  public getCurrentToken(): string | null {
    return this.cookieService.getCookie('currentUser');
  }

  public clearUserValue(): void {
    this.cookieService.deleteCookie('currentUser');
    this.userSubject?.next(undefined);
  }

  public updateRequireToChangePassword(require: boolean): void {
    let user = this.getCurrentUserValue();
    if (user) {
      user.require_to_change_password = require;
      this.setUserValue(user);
    }
  }

  public isLoggedIn(): boolean {
    return this.cookieService.getCookie('currentUser') !== null;
  }

  public loadProfile(): Observable<UserModel> {
    return this.apiService
            .getMe()
            .pipe(
              map(x => {
                const res = x as ResponseMessageProfileModel;
                const newRes: UserModel = {
                  id: res.id,
                  username: res.username,
                  first_name: res.first_name,
                  last_name: res.last_name,
                  is_superuser: res.is_superuser,
                  group: res.group,
                  require_to_change_password: res.require_to_change_password,
                  company_tax_id: res.company_tax_id,
                  see_keyin_page: res.see_keyin_page,
                  service_provider_option: res.service_provider_option,
                  see_setting_page: res.see_setting_page,
                  token: this.getCurrentToken() ?? ''
                };
                return newRes;
              })
            )
  }

  public onLogin(username: string, password: string): Observable<ResponseMessageLoginModel> {
    return this.httpClient.post(API_CONSTANT.login, {
              username: username,
              password: password
            })
            .pipe(
              map(x => x as ResponseMessageLoginModel)
            );
  }

  public onLogout(returnUrl?: string): void {
    this.clearUserValue()
    if (!navigator.onLine) {
      const baseUrl = '/login';
      const queryParams = returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : '';
      window.location.href = baseUrl + queryParams;
    } else {
      this.router.navigate(['/login'], returnUrl ? { queryParams: { returnUrl } } : undefined);
    }
  }

  public sendLogoutRequest(): void {
    if (this.isLoggedIn()) {
      this.apiService
        .logout()
        .subscribe({
          next: () => console.info(`[${new Date().toLocaleTimeString()}] Logout successful`),
          error: (err) => console.error(`[${new Date().toLocaleTimeString()}] Logout failed`, err)
        });
    }
  }

  public isUserInGroup(groupOrRoles: string | string[]): boolean {
    const user = this.getCurrentUserValue();
    const userGroup = user?.group || '';
    if (Array.isArray(groupOrRoles)) {
      return groupOrRoles.includes(userGroup);
    }
    return userGroup === groupOrRoles;
  }

  public isSuperAdmin(): boolean {
    const user = this.getCurrentUserValue();
    return user?.is_superuser || false;
  }

  public isUserInCompany(companyTaxId: string): boolean {
    const user = this.getCurrentUserValue();
    return user?.company_tax_id === companyTaxId;
  }
  
  public isAdvanceServiceProvider(): boolean {
    const option = this.getCurrentUserValue()?.service_provider_option;
    return option === 1 || option === 2;
  }

  public isSettingManual(): boolean {
    const option = this.getCurrentUserValue()?.service_provider_option;
    return option === 0 || option === 1;
  }

  // Page Permission

  public isKeyinPermission(): boolean {
    const user = this.getCurrentUserValue();
    return this.isAccountant() && user?.see_keyin_page === true;
  }

  public isSettingPermission(): boolean {
    const user = this.getCurrentUserValue();
    return this.isViewerImpAndHelp() || (this.isIT() && user?.see_setting_page === true);
  }

  public isSubmitToRdPermission(): boolean {
    return this.isViewerImpAndHelp() || (this.isAccountantAdmin() && this.isAdvanceServiceProvider());
  }

  public isRdSummaryPermission(): boolean {
    return this.isViewerImpAndHelp() || (this.isAccountantAdmin() && this.isAdvanceServiceProvider());
  }

  public isSalesTaxReportPermission(): boolean {
    return this.isUIH() || this.isAccountant() || this.isAccountantAdmin() || this.isViewerImpAndHelp();
  }

  public isDocumentSearchPermission(): boolean {
    return this.isUIH() || this.isAccountant() || this.isAccountantAdmin() || this.isViewerImpAndHelp();
  }

  public isEmailDashboardPermission(): boolean {
    return this.isAdmin() || this.isAccountant() || this.isAccountantAdmin() || this.isViewerImpAndHelp()
  }

  public isRevenueDocumentPermission(): boolean {
    return this.isUIH() || (this.isAccountantAdmin() && !this.isAdvanceServiceProvider()) || this.isViewerImpAndHelp()
  }

  public isFeeEnginePermission(): boolean {
    return this.isSCBBCMViewer();
  }

  public isDocumentUploadPermission(): boolean {
    return this.isAccountant() && !this.isSCIUser();
  }

  public isBulkTaxReportPermission(): boolean {
    return this.isUIH() || this.isAccountant() || this.isAccountantAdmin() || this.isViewerImpAndHelp();
  }

  public isAnnouncementPermission(): boolean {
    return this.isViewerImpAndHelp() || this.isViewerOperation();
  }

  public isDashboardPermission(): boolean {
    return this.isAdmin() || this.isUIH() || this.isAccountantAdmin() || this.isIT() || this.isAccountant() || this.isViewerImpAndHelp();
  }

  public isMonitoringPermission(): boolean {
    return this.isAdmin() || this.isUIH() || this.isIT() || this.isAccountant() || this.isViewerImpAndHelp();
  }

  public isDataPurgePermission(): boolean {
    return this.isAccountantAdmin() || this.isAccountant() || this.isIT()
  }

  // Check Roles

  public isViewerImpAndHelp(): boolean {
    return this.isUserInGroup('Viewer (Implementation and Helpdesk)');
  }

  public isAdmin(): boolean {
    return this.isUserInGroup('Admin') || this.isSuperAdmin();
  }

  public isAccountant(): boolean {
    return this.isUserInGroup('Maker(Admin)');
  }

  public isAccountantAdmin(): boolean {
    return this.isUserInGroup('Signer(Accounting Manager)');
  }

  public isIT(): boolean {
    return this.isUserInGroup('Viewer(IT)');
  }

  public isCallCenter(): boolean {
    return this.isUserInGroup('Call Center');
  }

  public isUIH(): boolean {
    return this.isUserInGroup('UIH');
  }

  public isSuperMaker(): boolean {
    return this.isUserInGroup('Super_maker');
  }

  public isMaker(): boolean {
    return this.isUserInGroup('Maker');
  }

  public isSuperChecker(): boolean {
    return this.isUserInGroup('Super_checker');
  }

  public isChecker(): boolean {
    return this.isUserInGroup('Checker');
  }

  public isViewerOperation(): boolean {
    return this.isUserInGroup('Viewer (Operation)');
  }

  public isSCIUser(): boolean {
    return this.isUserInCompany('0105531096240');
  }

  public isSCBBCM(): boolean {
    return this.isSuperMaker() || this.isMaker() || this.isSuperChecker() || this.isChecker()
      || this.isViewerOperation() || this.isViewerImpAndHelp();
  }

  public isSCBBCMViewer(): boolean {
    return this.isViewerImpAndHelp() || this.isViewerOperation();
  }

  public isSCBBCMManageUser(): boolean {
    return this.isSuperMaker() || this.isMaker() || this.isSuperChecker() || this.isChecker()
  }

}
