import { Injectable, OnDestroy } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subject, Subscription, concatMap, filter, fromEvent, of, takeUntil, tap } from 'rxjs';
import { AuthenticationService } from '../../service/authentication.service';
import { BroadcastService } from './boardcast-service';
import { HandleTranslateService } from './translate-service';
import { ApiService } from '../../service/api.service';
import { LanguageType } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class IdleService implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  private idleSubscription$: Subscription | null = null;

  private idleTime: number = 14 * 60;
  private timeout: number = 60;

  private language: LanguageType;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private authenticationService: AuthenticationService,
    private boardcastService: BroadcastService,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
  ) {
    this.subscribeToServices();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.idleSubscription$?.unsubscribe();
    this.idleSubscription$ = null;
    this.clearIdle();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  public watchIdle(): void {
    console.info(`[${new Date().toLocaleTimeString()}] Idle service is watching`);
    this.idle.setIdle(this.idleTime);
    this.idle.setTimeout(this.timeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idleSubscription$ = this.idle
      .onTimeout
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => console.warn(`[${new Date().toLocaleTimeString()}] Idle service is timeout`)),
        concatMap((res) => {
          if (document.visibilityState === 'visible') {
            return of(res);
          } else {
            return  fromEvent(window, 'visibilitychange')
            .pipe(
              filter(() => document.visibilityState === 'visible')
            )
          }
        })
      )
      .subscribe(() => {
        this.clearIdle();
        this.authenticationService.sendLogoutRequest();
        this.onSessionExpired();
        this.boardcastService.sendMessage({ type: 'session-timeout' });
      });
    this.keepalive.interval(30);
    this.idle.watch();
  }

  public clearIdle(): void {
    console.info(`[${new Date().toLocaleTimeString()}] Idle service is clearing`);
    this.idle.stop();
    this.keepalive.stop();
    this.idleSubscription$?.unsubscribe();
    this.idleSubscription$ = null;
  }

  public onSessionExpired(): void {
    setTimeout(() => {
      alert(this.language === 'th' ? 'สิ้นสุดการทำงานของระบบเนื่องจากไม่มีการใช้งานเกินกว่า 15 นาที กรุณาเข้าสู่ระบบอีกครั้ง' : 'Your session have expired due to inactive response more than 15 minutes. Please log in again.');
    }, 900);
    this.authenticationService.onLogout();
  }

}
