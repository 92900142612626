import { Inject, Injectable } from '@angular/core';
import { BroadcastChannelModel } from '../../interfaces';
import { Observable, Subject, fromEventPattern, map, takeUntil } from 'rxjs';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle } from '@ng-idle/core';
import { AuthenticationService } from '../../../service/authentication.service';
import { BROADCAST_CHANNEL } from './boardcast-init';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private messageSubject = new Subject<BroadcastChannelModel>();

  constructor(
    @Inject(BROADCAST_CHANNEL) private channel: BroadcastChannel
  ) {
    this.channel.onmessage = (event) => {
      this.messageSubject.next(event.data);
    };
  }

  public sendMessage(message: BroadcastChannelModel): void {
    this.channel.postMessage(message);
  }
  
  // public listen(callback: (message: any) => void) {
  //   this.channel.onmessage = (event) => {
  //     callback(event.data);
  //   };
  // }

  public listen(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  public closeChannel() {
    this.channel.close();
  }

}
