import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatablesComponent } from '../../../../shared/components/datatables/datatables.component';
import { EMPTY, Observable, Subject, catchError, concatMap, defer, delay, finalize, from, map, of, takeUntil, takeWhile, tap, throwError } from 'rxjs';
import { AuthenticationService } from '../../../../service/authentication.service';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { HttpClientService } from '../../../../service/http-client.service';
import { HandleModalService, HandleTranslateService, UtilitiesService } from '../../../../shared/services';
import { environment } from '../../../../../environments/environment';
import { DataPurgeResultModel, ResponseMessageDownloadZipModel, ResponseMessageDataPurgeResultModel } from '../../data-purge.interface';
import { DatatableCustomColumnModel, LanguageType } from '../../../../shared/interfaces';
import { NotificationService } from '../../../../service/notification.service';

@Component({
  selector: 'app-data-purge-result',
  templateUrl: './data-purge-result.component.html',
  styleUrl: './data-purge-result.component.scss'
})
export class DataPurgeResultComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public limitRow: number = 0;
  public rows: DataPurgeResultModel[] = [];
  public selectedRows: DataPurgeResultModel[] = [];
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'checkbox',
      label: '',
      name: '',
      type: 'checkbox',
      width: '4rem',
      minWidth: '4rem'
    },
    {
      id: 'fileName',
      label: 'NEW-TRANSLATE.COMMON.FILE-NAME',
      name: 'csv_name',
      type: 'string',
      defaultValue: '-',
      width: 'auto',
      minWidth: 'auto',
      cellClass: 'text-start text-break'
    },
    {
      id: 'fileSize',
      label: 'NEW-TRANSLATE.COMMON.FILE-SIZE',
      name: 'file_size_label',
      type: 'string',
      defaultValue: '-',
      width: '15%',
      minWidth: '15%'
    },
    {
      id: 'status',
      label: 'NEW-TRANSLATE.COMMON.STATUS',
      name: 'is_purge_in_progress',
      type: 'loading',
      width: '10%',
      minWidth: '10%'
    }
  ];

  public totalSize: number = 0;
  public totalItems: number = 0;

  public cancelDownload: boolean = false;
  public errorMessage: string | undefined;
  public isDataPurgeInProgress: boolean = false;

  public isLoadingRows: boolean = true;
  public isLoadingOther: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private httpClientService: HttpClientService,
    private handleModalService: HandleModalService,
    private utilitiesService: UtilitiesService,
    private notificationService: NotificationService,
    private handleTranslateService: HandleTranslateService

  ) {
    this.checkPermission();
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private checkPermission(): void {
    if (!this.authenticationService.isDataPurgePermission()) {
      this.router.navigate(['/']);
    }
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private initialSetting(): void {
    this.loadDataPurgeResult();
  }

  private loadDataPurgeResult(): void {
    this.isLoadingRows = true;
    const params = new HttpParams()
            .set('none_pagination', 'True')
            .set('is_purged_selected', 'True');
    this.httpClientService
      .get(`${ environment.apiURL }/api/tax_imports/`, params)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(res => {
          const newRes =  res as ResponseMessageDataPurgeResultModel;
          return [...newRes]
            .filter(x => x.csv_file)
            .map(x => {
            return { ...x, ...{ file_size_label: this.utilitiesService.formatBytes(x.file_size, 3), is_purge_in_progress: false } };
          });
        })
      )
      .subscribe({
        next: (res) => {
          if (res && res.length > 0) {
            this.rows = res;
            this.updateSummary(this.rows);
          } else {
            this.onBack();
          }
          this.isLoadingRows = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onStartDataPurge(): void {
    this.handleModalService
      .confirmModal({
        title: 'NEW-TRANSLATE.DATA-PURGE.RESULT.CONFIRM-PURGE-FILE',
        cancelButton: {
          show: true,
          id: 'cancelModalButton',
          name: 'NEW-TRANSLATE.MODAL-TEXT.NO'
        },
        confirmButton: {
          show: true,
          id: 'confirmModalButton',
          name: 'NEW-TRANSLATE.COMMON.YES'
        }
      })
      .result
      .then(
        () => {
          this.startDataPurge();
        }
      )
  }

  private startDataPurge(): void {
    this.isDataPurgeInProgress = true;
    this.cancelDownload = false;
    const selectedRows = [ ...this.selectedRows ];
    this.rows.forEach(row => {
      if (selectedRows.some(x => x.id === row.id)) {
        row.is_purge_in_progress = true;
      }
    });
    of(null)
     .pipe(
        tap(() => {
          this.rows.forEach(row => {
            if (this.selectedRows.some(x => x.id === row.id)) {
              row.is_purge_in_progress = true;
            }
          });
        }),
        delay(500),
        concatMap(() => from(selectedRows)),
        takeUntil(this.unsubscribe$),
        takeWhile(() => !this.cancelDownload),
        concatMap((item) => 
          defer(() => {
            if (this.cancelDownload) {
              this.stopProcessDownloadPipeline();
              return EMPTY;
            }
            const confirmDownload = window.confirm(this.language === 'th' ? 'ต้องการดาวน์โหลดต่อหรือไม่?' : 'Continue to download?');
            if (!confirmDownload) {
              this.cancelDownload = true;
              this.stopProcessDownloadPipeline();
              return EMPTY;
            } else {
              return this.processDownloadPipeline(item);
            }
          })
        ),
        catchError((err) => {
          console.error('Download error:', err);
          this.rows.forEach(row => row.is_purge_in_progress = false);
          this.cancelDownload = true;
          this.isDataPurgeInProgress = false;
          this.errorMessage = err;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
          return throwError(() => err);
        }),
        finalize(() => {
          this.isDataPurgeInProgress = false;
        })
     ).subscribe();
  }

  private stopProcessDownloadPipeline(): void {
    if (this.isDataPurgeInProgress) {
      this.isDataPurgeInProgress = false;
      this.rows.forEach(row => row.is_purge_in_progress = false);
    }
  }

  private processDownloadPipeline(item: DataPurgeResultModel): Observable<any> {
    return this.onDownloadCsv(item)
            .pipe(
              takeUntil(this.unsubscribe$),
              takeWhile(() => !this.cancelDownload),
              catchError((err) => {
                console.error('Download csv error:', err);
                this.notificationService.showErrorNotification(err.error);
                return of(null);
              }),
              concatMap(
                () => from(this.onDownloadZip(item)
                        .pipe(
                          catchError((err) => {
                            console.error('Download zip error:', err);
                            this.notificationService.showErrorNotification(err.error);
                            return of(null);
                          })
                        )
                )
              ),
              concatMap(
                () => from(this.onDeleteAllFiles(item))
              )
            );
  }

  private onDeleteAllFiles(item: DataPurgeResultModel): Observable<any> {
    return this.httpClientService
            .delete(`${ environment.apiURL }/api/tax_imports/${ item.id }/`)
            .pipe(
              takeUntil(this.unsubscribe$),
              takeWhile(() => !this.cancelDownload),
              tap(() => {
                this.rows = this.rows.filter(x => x.id !== item.id);
                this.selectedRows.shift();
                this.updateSummary(this.rows);
              }),
              catchError((err) => {
                return throwError(() => err);
              })
            );
  }

  private onDownloadZip(item: DataPurgeResultModel): Observable<any> {
    const tax_import_id = item.id;
    const zip_file_name = item.csv_name?.replace(/\.[^/.]+$/, '') + '_xml_pdf_files' || '';
    const data = {
      tax_import_id,
      zip_file_name
    };
    const params = new HttpParams().append('purge', 'True');
    return this.httpClientService
            .post(`${ environment.apiURL }/api/get_tax_data_zip_file_url/`, data, params)
            .pipe(
              takeUntil(this.unsubscribe$),
              takeWhile(() => !this.cancelDownload),
              map(x => x as ResponseMessageDownloadZipModel),
              tap((res) => {
                if (this.cancelDownload) return;
                if (res.zip_file && this.utilitiesService.isUrlExists(res.zip_file)) {
                  window.location.assign(res.zip_file);
                } else {
                  this.handleTranslateService
                    .get('NEW-TRANSLATE.COMMON')
                    .subscribe((trans) => {
                      this.notificationService.showCustomNotification({
                        severity: 'info',
                        summary: trans['INFO'],
                        detail: trans['FILE-DOES-NOT-EXISTS']
                      });
                    });
                }
              })
            );
  }

  private onDownloadCsv(item: DataPurgeResultModel): Observable<any> {
    return this.httpClientService
            .getBlob(item.csv_file)
            .pipe(
              takeWhile(() => !this.cancelDownload),
              map(x => x as Blob),
              tap((res) => {
                if (this.cancelDownload) return;
                this.utilitiesService.downloadFile(res, item.csv_name ?? 'unknow');
              })
            );
  }

  // public onStopDataPurge(): void {
  //   this.cancelDownload = true;
  // }

  public onDeselectDataPurge(): void {
    if (this.selectedRows && this.selectedRows.length === 0) return;
    this.isLoadingOther = true;
    const data = {
      tax_data_list: this.selectedRows.map(x => x.id),
      is_purged_selected: false
    };
    this.httpClientService
      .patch(`${ environment.apiURL }/api/patch_tax_import/`, data)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => {
          this.rows = this.rows.filter(y => !this.selectedRows.some(x => x.id === y.id));
          this.selectedRows = [];
          this.datatablesElement?.reset();
          this.updateSummary(this.rows);
          this.notificationService.showSuccess('succesfully deselecting files');
          this.isLoadingOther = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onBack(): void {
    this.router.navigate(['purge/list']);
  }

  private updateSummary(data: DataPurgeResultModel[] = []): void {
    this.totalItems = data.length;
    this.totalSize = data.reduce((total, item) => total + (item.file_size || 0), 0);
  }

}
